<template>
	<component :is="contentComponent" />
</template>

<script>
const _m = () => import('@/pages/home/m.vue')
const _pc = () => import('@/pages/home/pc.vue')
import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			contentComponent: null,
		}
	},
	metaInfo() {
		return {
			title: 'Daily Tarot - Your Free Daily Tarot Card Readings and Insights',
			meta: [
				{
					name: 'description',
					content: 'Discover your daily tarot card readings and gain insights into your life, love, career, and more. Visit Daily Tarot for free tarot readings, horoscopes, and personalized guidance.',
				},
			],
		}
	},
	computed: {
		...mapGetters(['deviceType']),
	},
	created() {
		console.log(`deviceType - ${this.deviceType}`)
		if (this.deviceType == 'desktop' || this.deviceType == 'pc') {
			this.contentComponent = _pc
			return
		}
		this.contentComponent = _m
	},
}
</script>
